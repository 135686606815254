import styled from "styled-components"
/* import media from "styled-media-query" */


export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const LanguageBtn = styled.button`
  margin-top: 10px;
  margin-left: 30px;
  color: ${props => props.theme.colors.white};
  color: ${props => props.color ? props.theme.colors.primary : props.theme.colors.white};
  transition: color 0.7s;
  font-size: 12px;
  font-family: "Josefin Sans", sans-serif;
  transition: color 0.7s;
  &:hover {
    color: ${props => props.theme.colors.success};
  }
  @media (max-width: 768px) {
    color: ${props => props.theme.colors.white};
  }

`
