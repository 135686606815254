import React from "react"
import * as S from "./styled"

import RightNav from "./RightNav"

const Burger = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <S.BurgerEl open={open} onClick={() => setOpen(!open)}>
        <div/>
        <div/>
        <div/>
      </S.BurgerEl>
      <RightNav open={open}/>
    </>
  )
}

export default Burger