import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Brand from "../Brand"
import Burger from "./Burger"

import * as S from "./styled"

import LanguageMenu from "../LanguageMenu"

const Navigation = () => {
  return (
    <S.WrapperNav id="nav">
      <S.WrapperBrand>
        <AniLink to="/">
          <Brand />
        </AniLink>
        <LanguageMenu/>
      </S.WrapperBrand>
      <S.Nav>
        <Burger />
      </S.Nav>
    </S.WrapperNav>
  )
}

export default Navigation
