import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"

const RightNav = ({ open, t, i18n  }) => {
  return (
    <S.Ul open={open}>
      <li>
        <AniLink  to="/#features">{t('navbar.features')}</AniLink>
      </li>
      <li>
        <AniLink  to="/#solutions">{t('navbar.solutions')}</AniLink>
      </li>
      <li>
        <AniLink  to="/#about">{t('navbar.aboutUs')}</AniLink>
      </li>
      <li>
        <AniLink to="/why" >
        {t('navbar.whyPyne')}
        </AniLink>
      </li>
      <li>
        <AniLink   to="/#contact">{t('navbar.contact')}</AniLink>
      </li>
      <S.Button
        href="https://portal.pyneapp.com/login?origin=site"
        target="_blank"
        rel="noopener noreferrer"
      >
        Log In
      </S.Button>
    </S.Ul>
  )
}

export default withTrans(RightNav)
