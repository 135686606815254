import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styled"

const LanguageMenu = () => {
  const { i18n } = useTranslation()

  const [values, setValues] = useState({
    language: "en",
  })
  console.log(values);

  const [navBar, setNavbar] = React.useState(false)
  const changeBackground = () => {
    //console.log(window.scrollY)
      if(window.scrollY >= 80) {
        setNavbar(true)
      } else {
          setNavbar(false)
        }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeBackground)
  }, [])
  

  function handleChange(language) {
    i18n.changeLanguage(language)
    setValues(oldValues => ({
      ...oldValues,
      language
    })) 
  }

  return (
    <S.Wrapper>
      <S.LanguageBtn color={navBar} onClick={() => handleChange('en')}>EN</S.LanguageBtn>
      <S.LanguageBtn color={navBar} onClick={() => handleChange('es')}>ES</S.LanguageBtn>
      <S.LanguageBtn color={navBar} onClick={() => handleChange('pt')}>PT</S.LanguageBtn>
    </S.Wrapper>
  )
}

export default LanguageMenu