import styled from "styled-components"

export const WrapperNav = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`

export const WrapperBrand = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: center;
`

export const Nav = styled.nav`
  width: 50%;
  height: 55px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;

  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 400;
  
  > ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  }

  > a {
    color: #fff;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
export const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 18px 10px;
    > a {
      color: #000;
      transition: color 0.7s;
      &:hover {
        color: ${props => props.theme.colors.grey};
      }
      @media (max-width: 768px) {
      color: #fff;
  }
    }
    
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: ${props => props.theme.colors.primary};
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 180px;
    padding-top: 100px;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`

export const BurgerEl = styled.div`
  height: 25px;
  width: 35px;
  /* position: fixed; */
  top: 30px;
  right: 25px;
  z-index: 20;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 25px;
    height: 0.15rem;
    background-color: ${({ open }) => (open ? "#ccc" : "#7a7a7a")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      height: 2px;
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
      height: 2px;
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      height: 2px;
    }
  }
`

export const Button = styled.a`
  width: 96px;
  height: 26px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  border-radius: 26px;
  transition: color 0.5s;
  transition: background-color 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.success};
  }
`
