import React from "react"
import Layout from "../components/Layout"
import NavigationPages from "../components/NavigationPages"

import SEO from "../components/seo"

import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const OurSolutionsPage = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Our Solutions" />
      <NavigationPages />
      <p>Our Solutions</p>
    </Layout>
  </ThemeProvider>
)

export default OurSolutionsPage